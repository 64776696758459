import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["preferredDeliveryDate", "printedCopyIntended", "shippingZone", "finishDate"];

  declare readonly preferredDeliveryDateTarget: HTMLInputElement;
  declare readonly printedCopyIntendedTarget: HTMLInputElement;
  declare readonly shippingZoneTarget: HTMLSelectElement;
  declare readonly finishDateTarget: HTMLInputElement;

  update(): void {
    const deliveryDate = new Date(this.preferredDeliveryDateTarget.value);
    if (isNaN(deliveryDate.getTime())) {
      this.finishDateTarget.value = "";
      return;
    }

    const isPrintedCopy = this.printedCopyIntendedTarget.checked;
    const shippingZone = this.shippingZoneTarget.value;
    let finishDate = new Date(deliveryDate);

    if (!isPrintedCopy) {
      finishDate.setDate(finishDate.getDate() - 1);
    } else {
      switch (shippingZone) {
        case "Germany":
          finishDate.setDate(finishDate.getDate() - 14);
          break;
        case "Rest of Europe":
          finishDate.setDate(finishDate.getDate() - 21);
          break;
        default:
          finishDate.setMonth(finishDate.getMonth() - 1);
          break;
      }
    }

    this.finishDateTarget.value = this.formatDate(finishDate);
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
}
