import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller<HTMLFormElement> {
  static targets = ["text", "input"]

  declare readonly textTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement

  connect() {
    if (this.textTarget.textContent === "") {
      this.textTarget.classList.add("d-none")
      this.inputTarget.classList.remove("d-none")
    }
  }

  edit() {
    const trimmedText = this.textTarget.textContent?.trim() || "";
    this.inputTarget.value = trimmedText;
    this.textTarget.classList.add("d-none");
    this.inputTarget.classList.remove("d-none");
    this.inputTarget.focus();
  }

  cancelEdit() {
    this.textTarget.classList.remove("d-none")
    this.inputTarget.classList.add("d-none")
  }

  commitEdit() {
    const trimmedValue = this.inputTarget.value.trim();
  
    if (!trimmedValue) {
      this.inputTarget.setCustomValidity("This field is required.");
      this.inputTarget.reportValidity();
      this.inputTarget.focus();
      return;
    } else {
      this.inputTarget.setCustomValidity("");
    }
  
    if (!this._checkValidity()) {
      return;
    }
  
    post(this.element.action, { body: new FormData(this.element) });
  
    this.textTarget.innerText = trimmedValue;
    this.cancelEdit();
  }
  

  submit(event) {
    event.preventDefault()
    this.commitEdit()
  }

  keydown(event) {
    if (event.key === "Escape") {
      this.cancelEdit()
    } else if (event.key === "Enter") {
      if (event.target.tagName === "TEXTAREA") {
        return
      } else {
        this.commitEdit()
      }
    }
  }

  windowClick(event) {
    if (!this.element.contains(event.target) && !this.inputTarget.classList.contains("d-none")) {
      this.commitEdit()
    }
  }

  _checkValidity() {
    this.element.classList.add("was-validated")
    return this.element.checkValidity()
  }
}
